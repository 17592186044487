export const newsArticleData = {
  article: true,
  title: 'Workplace manslaughter laws coming soon!',
  date: '8 Jul 2019',
  fullName: 'Bree Knoester',
  pagePath: 'https://hello-world.com/workplace-manslaughter-laws-coming-soon',
  metaDataTitle: 'This is the meta data title',
  metaDataDescription: 'This is the meta data description',
  metaDataImage: 'https://source.unsplash.com/featured/792x448',
  metaDataTwitterCreator: '@creator',
  desktopImage: 'https://source.unsplash.com/featured/792x448',
  tabletImage: 'https://source.unsplash.com/featured/574x322',
  mobileImage: 'https://source.unsplash.com/featured/328x184',
  imageDescription: 'Image alt text',
  breadcrumbs: [
    { title: 'Home', pageSlug: 'homepage' },
    { title: 'News Articles', pageSlug: 'news-articles' },
    {
      title: 'News Article Static',
      pageSlug: 'news-article-static',
      isActive: true
    }
  ]
};
